import _ from 'lodash'
import { isObjectNotNull } from './dataType'
import { ORDER_TYPE_DELIVERY } from '../constants'

export const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

export const getDistance = (lat1, long1, lat2, long2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(long2 - long1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const getAddressDetails = (addresses) => {
  let district = '';
  let subLocality = '';
  addresses?.forEach(addressData => {
    addressData?.address_components?.forEach(addressComponent => {
      const types = addressComponent.types;
      if (types.includes('neighborhood')) {
        district = addressComponent.short_name;
      } else if (
        types.includes('political') &&
        types.includes('sublocality') &&
        types.includes('sublocality_level_1')
      ) {
        subLocality = addressComponent.short_name;
      }
    });
  });
  return { district, subLocality };
};


export const isAddressValid = (address, orderType) => {
  if (isObjectNotNull(address)) {
    const { street, city, location } = address;
    return !((!street && orderType === ORDER_TYPE_DELIVERY) || !city || !isObjectNotNull(location));
  }
  return false;
};

export const parseAddress = addressComponents => {
  const address = {
    street: '',
    district: ''
  };
  const pointOfInterest = '';
  _.map(addressComponents, addressComponent => {
    if (addressComponent.types[0] === 'street_number') {
      address.streetNumber = addressComponent.short_name;
    }
    if (addressComponent.types[0] === 'route') {
      address.street = addressComponent.short_name;
    }

    if (addressComponent.types[0] === 'locality') {
      address.city = addressComponent.short_name;
    }

    if (addressComponent.types[0] === 'postal_code') {
      address.postalCode = addressComponent.short_name;
    }

    if (addressComponent.types[0] === 'street_number') {
      address.streetNumber = addressComponent.short_name;
    }
  });
  if (!address.street && pointOfInterest !== '') {
    address.street = pointOfInterest;
  }
  if (!address.streetNumber) {
    address.streetNumber = '';
  }
  return address;
};

export const getAddressFormatted = address => {
  if (address) {
    if (address.street) {
      return `${address.streetNumber ? `${address.streetNumber} ` : ''}${address.street}, ${
        address.city
      }`;
    }
    return `${address.city}`;
  }
  return '';
};
export const getFullAddressFormatted = address => {
  if (!address) return '';
 return `${address.streetNumber ? `${address.streetNumber} ` : ''}${address.street}, ${address.postalCode} ${address.city}`;
};
export const containsCircle = (point, center, radius) => {
  const distance =
    getDistance(point.latitude, point.longitude, center.latitude, center.longitude) * 1000;
  return distance < radius;
};

export const containsPolygon = (point, polygon) => {
  const x = point.latitude;
  const y = point.longitude;
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].latitude;
    const yi = polygon[i].longitude;
    const xj = polygon[j].latitude;
    const yj = polygon[j].longitude;

    // eslint-disable-next-line eqeqeq
    const intersect = (yi > y) != (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};
